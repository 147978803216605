import React, {Component} from 'react';
import Loading from "../Utility/Loading/Loading";
import {Api} from "../Api/Api";
import './SignContract.scss';
import FormRow from "../Form/Row";
import Layout from "../Layout/Layout";
import SignatureCanvas from 'react-signature-canvas';
import Widget from "../Layout/Element/Widget";
import FormElementText from "../Form/Element/Text";

class SignContract extends Component {
    constructor(props) {
        super(props);
        this.state = {
            key: this.props.match.params.key,
            contract: null,
            loading: true,
            selected: null,
            contract_selected: null,
            has_signature: false,
            screen_width: window.innerWidth,
            reject_reason: '',
            signature: '',
            object: false,
            has_contract_changes: false,
            initial_load: false,
            change_to_zero: false,
            change_to_guaranteed: false,
            previous_selected: ''
        };
    }

    componentDidMount() {
        this.updateContract();
    }

    updateContract() {
        Api.get('staff/contract/' + this.state.key + '/sign').then((response) => {
            if (response.ok) {
                this.handleContractData(response);
            } else {
                window.location.href = '/contract/' + this.state.key + '#expired';
            }
        });
    }

    handleContractData(response) {
        let contract = response.data.data.contract.data;
        let state = {loading: false, contract: response.data.data, initial_load: true};
        if (contract.allow_objection === false && contract.allow_zero_hour === false) {
            state.selected = 'accept';
        }
        let staff_contract = response.data.data
        if (staff_contract.zero_hours_selected) {
            state.selected = 'zero';
        }
        if (staff_contract.object_selected) {
            state.reject_reason = staff_contract.object_reason;
            state.selected = 'reject';
        }
        if (staff_contract.accept_selected) {
            state.selected = 'accept';
        }
        if (this.state.contract && this.state.contract.rendered_html !== response.data.data.rendered_html) {
            state.has_changes = true;
        } else {
            state.has_changes = false;
        }
        state.contract_selected = state.selected;
        this.setState(state);
    }

    handleClear(e) {
        Api.post('staff/contract/' + this.state.key + '/action', {action: 6}).catch(() => {
        });
        this.setState({has_signature: false});
        this.sigCanvas.clear();
    }

    onSignatureEnd() {
        this.setState({signature: this.sigCanvas.toDataURL('image/svg+xml')});
        if (this.state.has_signature === false) {
            Api.post('staff/contract/' + this.state.key + '/action', {action: 5}).catch(() => {
            });
            this.setState({has_signature: true})
        }
    }

    handleSave(e) {
        this.setState({loading: true});
        Api.post('staff/contract/' + this.state.key + '/sign', {
            signature: this.sigCanvas.toDataURL()
        }).then((response) => {
            if (response.ok) {
                this.handleContractData(response);
            }
        });
    }

    handleIndicateZero(e) {
        if (this.state.selected === 'zero') {
            return;
        }
        if (this.state.contract_selected === 'zero') {
            this.setState({selected: 'zero'});
            return;
        }
        this.setState({
            change_to_zero: true,
            change_to_guaranteed: false,
            selected: '',
            previous_selected: this.state.selected
        });
    }

    handleIndicateGuaranteed(e) {
        if (this.state.selected === 'accept') {
            return;
        }
        if (this.state.contract_selected === 'accept') {
            this.setState({selected: 'accept'});
            return;
        }
        this.setState({
            change_to_zero: false,
            change_to_guaranteed: true,
            selected: '',
            previous_selected: this.state.selected
        });
    }

    handleZero(e) {
        this.setState({loading: true, selected: 'zero', change_to_zero: false, change_to_guaranteed: false});
        Api.post('staff/contract/' + this.state.key + '/zero').then((response) => {
            if (response.ok) {
                this.handleContractData(response);
            }
        });
    }

    handleAccept(e) {
        this.setState({loading: true, selected: 'accept', change_to_zero: false, change_to_guaranteed: false});
        Api.post('staff/contract/' + this.state.key + '/guaranteed').then((response) => {
            if (response.ok) {
                this.handleContractData(response);
            }
        });
    }

    handleReset(e) {
        this.setState({
            loading: true,
            selected: '',
            contract_selected: '',
            change_to_zero: false,
            change_to_guranteed: false
        });
        Api.post('staff/contract/' + this.state.key + '/reset').then((response) => {
            if (response.ok) {
                this.handleContractData(response);
            }
        })
    }

    handleObject(e) {
        this.setState({selected: 'object', change_to_zero: false, change_to_guaranteed: false});
        Api.post('staff/contract/' + this.state.key + '/action', {action: 4}).catch(() => {
        });
    }

    handleSaveReject(e) {
        this.setState({loading: true});
        Api.post('staff/contract/' + this.state.key + '/object', {reason: this.state.reject_reason}).then((response) => {
            if (response.ok) {
                this.setState({loading: false, contract: response.data.data});
            }
        });
    }

    handleIndicateCancel(e) {
        this.setState({
            change_to_zero: false,
            change_to_guaranteed: false,
            selected: this.state.previous_selected,
            previous_selected: ''
        });
    }

    render() {
        if (this.state.loading === true) {
            return <Loading/>
        }

        let complete = this.state.contract.status.data.complete;
        if (complete) {
            return <Layout>
                <div className="alert alert-success">You have accepted your contract! You will have been e-mailed a
                    digital copy of your contract. If you wish to download your contract now <a
                        className="btn btn-success" href={'/contract/' + this.state.key + '/download'}>Click Here</a>
                </div>
            </Layout>
        }

        let can_proceed = this.state.has_signature && this.state.selected;
        let proceed_text = 'Sign Contract';

        if (this.state.has_signature) {
            can_proceed = true;
        } else {
            proceed_text = 'Please sign above';
        }

        let width = this.state.width < 300 ? this.state.width : 300;
        let height = width / 3;
        let contract = this.state.contract.contract.data;
        let show_options = false;
        if (contract.allow_zero_hour) {
            show_options = true;
        }
        if (contract.allow_objection) {
            show_options = true;
        }

        let show_complete_options = false;
        if (this.state.contract_selected && (contract.allow_objection || contract.allow_zero_hour)) {
            show_options = false;
            show_complete_options = true;
        }
        return <Layout>
            <h1 className="hidden-print">Sign my contract</h1>
            {this.state.contract.objected ?
                <div className="alert alert-danger">You have currently indicated that you object to this change in
                    terms. <br /><br />If you change your mind you can re-visit this page at any time up to the 2nd of March by clicking on the link in your contract e-mail.</div> : null}
            {this.state.contract_selected ? <div>
                {contract.allow_zero_hour ? <div className="go-back-holder alert alert-info">
                    <div className="row">
                        <div className="col-xs-8 col-md-10">
                            <p>You are currently viewing
                                a {this.state.contract_selected === 'accept' ? 'minimum ' + this.state.contract.contracted_hours + ' hour' : 'zero hour'} contract.</p>
                        </div>
                        <div className="col-xs-4 col-md-2">
                            <button className="btn btn-default btn-sm pull-right" onClick={(e) => this.handleReset(e)}>
                                <i className="fa fa-arrow-circle-left"/> Go back <span className="hidden-sm hidden-xs">to selection</span>
                            </button>
                        </div>
                    </div>
                </div> : null}
                <Widget title="Contract">
                    <iframe src={'/contract/' + this.state.key + '/copy'} className="copy-container"
                            id="contract_iframe"
                            title="Contract Content"/>
                </Widget>
                {contract.allow_zero_hour ? <div className="go-back-holder alert alert-info">
                    <div className="row">
                        <div className="col-xs-8 col-md-10">
                            <p>You are currently viewing
                                a {this.state.contract_selected === 'accept' ? 'minimum ' + this.state.contract.contracted_hours + ' hour' : 'zero hour'} contract.</p>
                        </div>
                        <div className="col-xs-4 col-md-2">
                            <button className="btn btn-default btn-sm pull-right" onClick={(e) => this.handleReset(e)}>
                                <i className="fa fa-arrow-circle-left"/> Go back <span className="hidden-sm hidden-xs">to selection</span>
                            </button>
                        </div>
                    </div>
                </div> : null}
            </div> : null}
            {show_options ? <div className="options">
                <Widget title="Please choose an option from below">
                    {!this.state.contract_selected ?
                        <div className="alert alert-info"><i className="fa fa-info"/> Once you have selected an option
                            you will have the chance to review your contract before signing or objecting.</div> : null}
                    <div className="option-buttons">
                        {contract.allow_zero_hour ?
                            <button
                                className={this.state.selected === 'zero' ? 'btn btn-success active' : 'btn btn-success'}
                                onClick={(e) => this.handleZero(e)}>
                                {this.state.selected === 'zero' ? <i className="fa fa-check"/> : null} I wish
                                to {this.state.contract_selected === 'zero' ? 'remain on' : 'view'} a zero
                                hour contract
                            </button> : null}
                        <button
                            className={this.state.selected === 'accept' ? 'btn btn-success active' : 'btn btn-success'}
                            onClick={(e) => this.handleAccept(e)}>
                            {this.state.selected === 'accept' ? <i className="fa fa-check"/> : null} I wish
                            to {this.state.contract_selected === 'accept' ? 'accept' : 'view'} a
                            minimum {this.state.contract.contracted_hours} hour contract
                        </button>
                    </div>
                </Widget>
            </div> : null}

            {this.state.change_to_zero === true ? <Widget title="Change to zero hour">
                {/*<p>{this.state.contract.contract.data.agree_text}</p>*/}
                {this.state.contract_selected ?
                    <p>NB: this will change the terms of your contract above. By clicking "Proceed" this page will
                        reload with new contract terms.</p> :
                    <p>NB: This page will reload with your contract draft above once you click "Proceed"</p>}
                <button className="btn btn-success" onClick={(e) => this.handleZero(e)}>Proceed</button>
                <button className="btn btn-warning pull-right" onClick={(e) => this.handleIndicateCancel(e)}>Cancel
                </button>
            </Widget> : null}
            {this.state.change_to_guaranteed === true ?
                <Widget title="Change to guaranteed minimum hours">
                    {/*<p>{this.state.contract.contract.data.zero_hours_selected}</p>*/}
                    {this.state.contract_selected ?
                        <p>NB: this will change the terms of your contract above. By clicking "Proceed" this page will
                            reload with new contract terms.</p> :
                        <p>NB: This page will reload with your contract draft above once you click "Proceed"</p>}
                    <button className="btn btn-success" onClick={(e) => this.handleAccept(e)}>Proceed</button>
                    <button className="btn btn-warning pull-right"
                            onClick={(e) => this.handleIndicateCancel(e)}>Cancel
                    </button>
                </Widget> : null}

            <div className="row">
                {show_complete_options && contract.allow_objection ? <div className="col-md-12">
                    <div className="alert alert-info"><i className="fa fa-info"/> Please choose 1 of the 2 options
                        below to complete this process.
                    </div>
                </div> : null}
                <div className={contract.allow_objection ? "col-md-6" : 'col-md-12'}>

                    {this.state.selected === 'accept' || this.state.selected === 'zero' ? <Widget
                        title={contract.allow_objection ? 'Option 1: Accept & Sign Contract' : 'Accept & Sign Contract'}>
                        <div>
                            <div id="canvas-wrapper">
                                <p>{this.state.contract.zero_hours_selected ? contract.zero_hour_text : this.state.contract.contract.data.agree_text}</p>
                                <SignatureCanvas canvasProps={{
                                    width: width,
                                    height: height,
                                    className: 'signature'
                                }}
                                                 penColor={'black'}
                                                 ref={(ref) => {
                                                     this.sigCanvas = ref
                                                 }} onEnd={() => this.onSignatureEnd()}/>
                            </div>
                            <div className="row" style={{maxWidth: (width + 33) + 'px'}}>
                                <div className="col-xs-6">
                                    <button className="btn btn-success" disabled={!can_proceed}
                                            onClick={(e) => this.handleSave(e)}>{proceed_text}</button>
                                </div>
                                <div className="col-xs-6">
                                    {this.state.has_signature ?
                                        <button className="btn btn-warning pull-right"
                                                onClick={(e) => this.handleClear(e)}>Clear Signature</button> : null}
                                </div>
                            </div>
                        </div>
                    </Widget> : null}
                </div>
                <div className="col-md-6">
                    {this.state.contract_selected && contract.allow_objection ?
                        <Widget title="Option 2: Object to Contract">
                            <p>{contract.objection_text}</p>
                            <FormRow label="Please enter your reason for rejecting these terms">
                                <FormElementText name="reason" value={this.state.reject_reason}
                                                 onChange={(e) => this.setState({reject_reason: e.target.value})}/>
                            </FormRow>
                            <button className="btn btn-danger" onClick={(e) => this.handleSaveReject(e)}
                                    disabled={!this.state.reject_reason || this.state.reject_reason.length < 10}>Object
                                to contract
                            </button>
                        </Widget> : null}
                </div>
            </div>
        </Layout>
    }
}

export default SignContract;