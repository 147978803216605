import React, {Component} from 'react';
import PropTypes from 'prop-types';

class FormRow extends Component {
    render() {
        var className = 'form-group';
        if (this.props.error) {
            className = className + ' has-error';
        }
        return (
            <div className={className}>
                <label className="control-label" htmlFor={this.props.children.props.name}>{this.props.label}</label>
                {this.props.children}
            </div>
        )
    }
}

FormRow.propTypes = {
    label: PropTypes.string,
    error: PropTypes.string
};
export default FormRow;