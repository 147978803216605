import React, {Component} from 'react';
import PropTypes from 'prop-types';

class FormElementText extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: this.props.value
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.value !== this.props.value) {
            this.setState({value: nextProps.value});
        }
    }

    static defaultProps = {
        defferredUpdate: true
    }

    onChange(e) {
        this.setState({value: e.target.value});
        this.props.onChange({
            target: {
                name: e.target.name,
                value: e.target.value
            }
        });
    }

    onBlur(e) {
        if (this.props.onBlur) {
            this.props.onBlur(e);
            return;
        }
        if (this.props.defferredUpdate) {
            if (this.props.onChange) {
                this.props.onChange(e);
            }
        }
    }

    render() {
        var className = this.props.className;
        if (!className) {
            className = 'form-control';
        }
        var id = this.props.id;
        if (!id) {
            id = this.props.name;
        }
        var type = 'text';
        if (this.props.number === true) {
            type = 'number';
        }
        return <input type={type} name={this.props.name} id={id} value={this.state.value}
                      className={className} onChange={e => this.onChange(e)} disabled={this.props.disabled}
                      onBlur={e => this.onBlur(e)} placeholder={this.props.placeholder}
                      onFocus={this.props.onFocus} maxLength={this.props.maxLength} max={this.props.max}
                      min={this.props.min}/>
    }
}

FormElementText.propTypes = {
    value: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    name: PropTypes.string.isRequired,
    className: PropTypes.string,
    id: PropTypes.string,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    placeholder: PropTypes.string,
    defferredUpdate: PropTypes.bool,
    number: PropTypes.bool,
    max: PropTypes.number,
    min: PropTypes.number,
    maxLength: PropTypes.number
};

export default FormElementText;